<script lang="ts" setup>
import type { State } from './state';

import { LoginSchema } from '~/schemas/auth';

const props = defineProps<State>();

const emits = defineEmits<{
  (e: 'update:password', value: string): void;
  (e: 'back'): void;
  (e: 'forgotPassword'): void;
}>();

const onUpdatePassword = (value: string): void => {
  emits('update:password', value);
};

const onBack = (): void => {
  emits('back');
};

const onForgotPassword = (): void => {
  emits('forgotPassword');
};
</script>

<template>
  <UForm
    class="password-form py-4 sm:pt-20 w-full max-w-96"
    :schema="LoginSchema"
    :state="props"
    :validate-on="['submit']"
  >
    <UCard
      class="password-form__card shadow-2xl"
      :ui="{ body: { base: 'space-y-4' } }"
    >
      <template #header>
        <div class="flex gap-5 flex-col text-center">
          <h1 class="text-xl">
            Hello
          </h1>

          <span
            class="font-semibold text-2xl"
            data-test="full-name"
          >
            {{ props.fullName }}
          </span>
        </div>
      </template>

      <UFormGroup
        label="Password"
        name="password"
      >
        <UInput
          v-focus
          :model-value="props.password"
          icon="ic:round-lock"
          type="password"
          size="xl"
          data-test="password"
          @update:model-value="onUpdatePassword"
        />
      </UFormGroup>

      <template #footer>
        <UButton
          block
          class="bg-company-1 py-4 font-semibold uppercase"
          label="Sign in"
          type="submit"
          data-test="submit"
        />

        <div class="flex justify-between py-2 text-sm">
          <h2>
            <ULink
              class="font-semibold text-company-1"
              data-test="back"
              @click="onBack()"
            >
              Use other e-mail
            </ULink>
          </h2>

          <h2>
            <ULink
              class="font-semibold text-company-1"
              data-test="forgot-password"
              @click="onForgotPassword()"
            >
              Forgot password?
            </ULink>
          </h2>
        </div>
      </template>
    </UCard>
  </UForm>
</template>

<style lang="scss" scoped>
.component-enter-active,
.component-leave-active {
  transition: all 0.2s ease;

  .password-form__card {
    div {
      transition: all 0.2s ease;
    }
  }
}

.component-enter-from,
.component-leave-to {
  transform: scale(0.85);

  .password-form__card {
    div {
      opacity: 0;
    }
  }
}

.component-leave-from,
.component-enter-to {
  .password-form__card {
    div {
      opacity: 1;
    }
  }
}
</style>
