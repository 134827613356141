<script lang="ts" setup>
import type { State } from './state';

import { LoginEmailSchema } from '~/schemas/auth';

type Props = Pick<State, 'email' | 'remember'> & { enableGuest?: boolean };

const props = defineProps<Props>();

const emits = defineEmits<{
  (e: 'update:email', value: string): void;
  (e: 'update:remember', value: boolean): void;
  (e: 'guest'): void;
}>();

const form = ref();
const errorMessage = ref<string>('');

const onUpdateEmail = (value: string): void => {
  emits('update:email', value);
};

const onUpdateRemember = (value: boolean): void => {
  emits('update:remember', value);
};

const onClearErrorMessage = (): void => {
  errorMessage.value = '';
};

const onGuest = async (): Promise<void> => {
  if (!props.email) {
    errorMessage.value = 'Enter email for order confirmation';
    return;
  }

  try {
    await form.value.validate();
    emits('guest');
  } catch {
    //
  }
};
</script>

<template>
  <UForm
    ref="form"
    class="email-form py-4 sm:pt-20 w-full max-w-96"
    :schema="LoginEmailSchema"
    :state="props"
    :validate-on="['submit']"
  >
    <UCard
      class="email-form__card shadow-2xl"
      :ui="{ body: { base: 'space-y-4' } }"
    >
      <template #header>
        <div class="text-center">
          <h1 class="font-semibold text-2xl">
            Sign in to your account
          </h1>
        </div>
      </template>

      <UFormGroup
        label="Email"
        name="email"
        :error="errorMessage"
      >
        <UInput
          v-focus
          :model-value="props.email"
          icon="ic:round-person"
          size="xl"
          data-test="email"
          @update:model-value="onUpdateEmail"
        />
      </UFormGroup>

      <UCheckbox
        :model-value="props.remember"
        label="Remember me"
        data-test="remember"
        @update:model-value="onUpdateRemember"
      />

      <template #footer>
        <div class="flex gap-4">
          <UButton
            v-if="props.enableGuest"
            block
            class="flex-1 bg-company-1 py-4 font-semibold uppercase"
            label="Guest"
            data-test="guest"
            @click="onGuest()"
          />

          <UButton
            block
            class="flex-1 bg-company-1 py-4 font-semibold uppercase"
            :label="props.enableGuest ? 'Login' : 'Next'"
            type="submit"
            data-test="submit"
            @click="onClearErrorMessage"
          />
        </div>

        <div class="flex justify-between py-2 text-sm">
          <h2>
            New on Foodinn?

            <NuxtLink
              class="font-semibold text-company-1 cursor-pointer"
              data-test="sign-up"
              :to="{ name: 'login-register' }"
            >
              Sign Up
            </NuxtLink>
          </h2>
        </div>
      </template>
    </UCard>
  </UForm>
</template>

<style lang="scss" scoped>
.component-enter-active,
.component-leave-active {
  transition: all 0.2s ease;

  .email-form__card {
    div {
      transition: all 0.2s ease;
    }
  }
}

.component-enter-from,
.component-leave-to {
  transform: scale(0.85);

  .email-form__card {
    div {
      opacity: 0;
    }
  }
}

.component-leave-from,
.component-enter-to {
  .email-form__card {
    div {
      opacity: 1;
    }
  }
}

.action-link {
  text-decoration-line: none;
}
</style>
