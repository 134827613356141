<script lang="ts" setup>
import { APIError } from '#imports';

import CardLoading from '~/components/common/CardLoading.vue';
import EmailForm from '~/components/login/EmailForm.vue';
import NeedUpPass from '~/components/login/NeedUpPass.vue';
import PasswordForm from '~/components/login/PasswordForm.vue';
import type { State } from '~/components/login/state';

definePageMeta({
  middleware: 'auth-and-menu'
});

const route = useRoute();
const router = useRouter();
const notify = useNotify();
const authStore = useAuthStore();
const profileStore = useProfileStore();
const preferenceStore = usePreferenceStore();

useSeoMeta({
  title: 'Sign in'
});

const state = reactive<State>({
  fullName: '',
  remember: false
});

const step = ref<'email' | 'password' | 'reset'>('email');
const isLoading = ref<boolean>(false);
const loadingTitle = ref<string>('');

const shouldShowEnableGuest = computed<boolean>(() => {
  return (
    route.query.redirect === '/checkout/takeaway' &&
    !!preferenceStore.preference.general.enableGuestLogin
  );
});

const handleEmail = async (): Promise<void> => {
  if (!state.email) {
    return;
  }

  try {
    const profile = await profileStore.getByUsername(state.email);

    if (!profile) {
      return;
    }

    if (profile.user.isDisabled) {
      throw new Error('Your account has been removed.');
    }

    if (state.remember) {
      authStore.setRemember(state.email);
    } else {
      authStore.setRemember('');
    }

    state.fullName = profile.fullName || profile.user.username;

    if (profile.user.needUpPass) {
      await authStore.forgotPassword(state.email);
      step.value = 'reset';
      return;
    }

    if (!!preferenceStore.preference.general.enableEmailVerification &&
      !profile.user.isConfirmed) {
      await router.push({
        name: 'login-confirmemail',
        query: { email: state.email }
      });
      return;
    }
  } catch (e) {
    authStore.setRemember('');
    state.email = '';

    let message = '';

    if (e instanceof APIError) {
      message = e.code === 404 ? 'Account not found.' : e.message;
    } else {
      message = (e as Error).message;
    }

    throw new Error(message);
  }
};

const validateEmail = async (): Promise<boolean> => {
  try {
    loadingTitle.value = 'Loading...';
    isLoading.value = true;
    await handleEmail();
    return true;
  } catch (e) {
    notify.error((e as Error).message);
    return false;
  } finally {
    isLoading.value = false;
  }
};

const onEmailSubmit = async (): Promise<void> => {
  if (!(await validateEmail())) {
    return;
  }

  if (step.value === 'email') {
    step.value = 'password';
  }
};

const onGuest = async (): Promise<void> => {
  if (!state.email) {
    return;
  }

  try {
    loadingTitle.value = 'Logging In...';
    isLoading.value = true;
    const email = state.email!.toLowerCase().trim();

    await authStore.guestLogin(email);
    await router.push({ path: route.query.redirect as string });
  } catch {
    isLoading.value = false;
  }
};

const onPasswordSubmit = async (): Promise<void> => {
  try {
    loadingTitle.value = 'Logging In...';
    isLoading.value = true;
    const email = state.email!.toLowerCase().trim();
    await authStore.login(email, state.password!);

    if (route.query.redirect) {
      await router.push({ path: route.query.redirect as string });
      return;
    }

    await router.push({ name: 'index' });
  } catch {
    isLoading.value = false;
  }
};

const onTryOtherEmail = (): void => {
  state.email = '';
  state.remember = false;

  step.value = 'email';
};

const onGoForgotPassword = async (): Promise<void> => {
  await router.push({
    name: 'login-forgotpassword',
    query: { email: state.email! }
  });
};

const handleRemember = async (): Promise<void> => {
  if (authStore.remember) {
    state.email = authStore.remember;
    state.remember = true;

    validateEmail();
    step.value = 'password';
  }
};

await handleRemember();
</script>

<template>
  <UContainer class="login min-h-svh flex flex-col items-center">
    <Transition
      name="component"
      mode="out-in"
    >
      <div
        v-if="isLoading"
        class="pt-20"
      >
        <CardLoading :title="loadingTitle" />
      </div>

      <EmailForm
        v-else-if="step === 'email'"
        v-model:email="state.email"
        v-model:remember="state.remember"
        :enable-guest="shouldShowEnableGuest"
        @guest="onGuest"
        @submit="onEmailSubmit"
      />

      <PasswordForm
        v-else-if="step === 'password'"
        v-bind="state"
        v-model:password="state.password"
        @back="onTryOtherEmail"
        @forgot-password="onGoForgotPassword"
        @submit="onPasswordSubmit"
      />

      <NeedUpPass
        v-else
        @back="onTryOtherEmail"
      />
    </Transition>
  </UContainer>
</template>

<style lang="scss" scoped>
.login {
  padding-top: var(--header-height);
}
</style>
