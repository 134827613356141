<script lang="ts" setup>
const emits = defineEmits<{
  (e: 'back'): void;
}>();

const onBack = (): void => {
  emits('back');
};
</script>

<template>
  <UCard
    class="need-up-pass__card shadow-2xl my-4 sm:mt-20 w-full max-w-96"
    :ui="{ body: { base: 'space-y-4' } }"
  >
    <template #header>
      <div class="text-center">
        <h1 class="font-semibold text-2xl">
          Please reset your password
        </h1>
      </div>
    </template>

    <div class="mb-4 p-4 border-s-4 border-green-600 rounded bg-card-contrast font-semibold">
      <span>
        We sent you an e-mail to reset your password. Please check your
        inbox and follow the instructions to access your account.
      </span>
    </div>

    <template #footer>
      <div class="flex justify-between py-2 text-sm">
        <h2>
          <ULink
            class="font-semibold text-company-1"
            data-test="back"
            @click="onBack()"
          >
            Use other e-mail
          </ULink>
        </h2>
      </div>
    </template>
  </UCard>
</template>

<style lang="scss" scoped>
.component-enter-active,
.component-leave-active {
  transition: all 0.2s ease;

  .need-up-pass__card {
    div {
      transition: all 0.2s ease;
    }
  }
}

.component-enter-from,
.component-leave-to {
  transform: scale(0.85);

  .need-up-pass__card {
    div {
      opacity: 0;
    }
  }
}

.component-leave-from,
.component-enter-to {
  .need-up-pass__card {
    div {
      opacity: 1;
    }
  }
}
</style>
